<script lang="ts">
    import type { Element, SectionVariants } from '../types'
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let element: Element = 'section'
    export let variant: SectionVariants = 'white'

    $: classes = concatClasses(['section', `section-${variant}`, classNames])
</script>

<svelte:element this={element} class={classes} {...$$restProps}>
    <slot />
</svelte:element>

<style global lang="postcss">
    .section {
        padding: theme('spacing.12') 0;

        @media screen(md) {
            padding: theme('spacing.16') 0;
        }

        @media screen(xl) {
            padding: theme('spacing.20') 0;
        }
    }

    .section-dark {
        background-color: theme('colors.nmd-800');
    }

    .section-light {
        color: theme('colors.gray-600');
        background-color: theme('colors.gray-50');
    }

    .section-white {
        color: theme('colors.nmd-700');
        background-color: theme('colors.white');
    }
</style>
